import React from 'react';
import Container from '@mui/material/Container';
import Hero from './Hero';
import ProductFilter from './ProductFilter'; // Import the new component
import './IndexPage.css';

export default function IndexPage() {
  return (
    <Container>
      <Hero />
      <ProductFilter /> {/* Use the new component here */}
    </Container>
  );
}