import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Divider,
  Avatar,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import axios from 'axios';

const OrderRowPage = () => {
  const { trade_uuid } = useParams(); // Use the full trade_uuid
  const navigate = useNavigate();

  const [order, setOrder] = useState(null); // State to hold the order data
  const [error, setError] = useState(null);
  const [openTrackingDialog, setOpenTrackingDialog] = useState(false);
  const [trackingID, setTrackingID] = useState('');
  const [carrier, setCarrier] = useState(''); // State to hold the carrier
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    if (order && order.product_id) {
      const cachedProduct = localStorage.getItem(order.product_id);
      if (cachedProduct) {
        setProductDetails(JSON.parse(cachedProduct));
      }
    }
  }, [order]);

  // List of common carriers
  const carriers = [
    'UPS',
    'FedEx',
    'DHL',
    'USPS',
    'Canada Post',
    'Royal Mail',
    'Australia Post',
    'Japan Post',
    'La Poste',
    'Other',
  ];

  const fetchOrder = useCallback(async () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
    if (isLocal) {
      try {
        const mockData = await import('./fixtures/trades.json'); // Local mock data
        const orders = mockData.default;
        const orderData = orders.find((order) => order.trade_uuid === trade_uuid);
        if (orderData) {
          setOrder(orderData);
        } else {
          setError('Order not found');
        }
      } catch (error) {
        console.error('Error loading mock data:', error);
        setError('Failed to load mock data');
      }
    } else {
      try {
        const response = await axios.get('/api/orders/sell/trades'); // API request for production
        const orders = response.data;
        const orderData = orders.find((order) => order.trade_uuid === trade_uuid); // Filter by trade_uuid
        if (orderData) {
          setOrder(orderData);
        } else {
          setError('Order not found');
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError('Failed to fetch orders');
      }
    }
  }, [trade_uuid]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleOpenTrackingDialog = () => {
    setOpenTrackingDialog(true); // Open the tracking ID dialog
  };

  const handleCloseTrackingDialog = () => {
    setOpenTrackingDialog(false);
  };

  const handleAddTrackingID = async () => {
    if (!trackingID || !carrier || !order) {
      return; // Don't proceed if tracking ID, carrier, or order is not available
    }

    const shipmentData = {
      order_uuid: order.order_uuid,
      trade_uuid: trade_uuid,
      status: 'pre_transit',
      carrier: carrier,
      tracking_number: trackingID,
      address_uuid: order.address && order.address.AddressUUID ? order.address.AddressUUID : null,
    };

    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    if (isLocal) {
      // Mock the API call locally
      console.log('Mocking shipment creation:', shipmentData);
      setTimeout(() => {
        console.log('Shipment created successfully (mocked)');
        setOrder({ ...order, shipment_id: 'mock-shipment', tracking_number: trackingID });
        setOpenTrackingDialog(false);
      }, 1000); // Simulate delay
    } else {
      // Send request to backend for production
      try {
        const response = await axios.post('/api/create-shipment', shipmentData);
        if (response.status === 200) {
          setOrder({ ...order, shipment_id: 'shipment_id', tracking_number: trackingID });
          console.log('Shipment created successfully');
        }
      } catch (error) {
        console.error('Error creating shipment:', error);
      }
      setOpenTrackingDialog(false);
    }
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!order) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Utility function to format cents to dollars
  const formatToDollars = (cents) => {
    return `$${(cents / 100).toFixed(2)}`;
  };
    
    // Utility function to convert the shipment status to human-readable format
    const getHumanReadableStatus = (status) => {
        const statusMap = {
        pre_transit: "Pre Transit",
        in_transit: "In Transit",
        out_for_delivery: "Out for Delivery",
        delivered: "Delivered",
        return_to_sender: "Return to Sender",
        failure: "Failure",
        unknown: "Unknown",
        };

        // Return the mapped status or 'Unknown' if not found
        return statusMap[status] || "Unknown";
    };
  

  // Get last 6 digits for display as order number
  const displayOrderNumber = trade_uuid.slice(-6).toUpperCase();
  const fulfillmentStatus = order.shipment_id ? 'Fulfilled' : 'Unfulfilled';
  const deliveryStatus = getHumanReadableStatus(order.shipment_status);

  const paymentStatus = order.payment_status || 'Paid'; // Assuming 'Paid' for example

  return (
    <Box sx={{ padding: 4 }}>
      {/* Header with Back Button and Trade Number */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Button onClick={handleBackClick} startIcon={<ArrowBackIcon />} sx={{ textTransform: 'none' }}>
          Orders
        </Button>
        <Typography variant="h5" component="div" sx={{ ml: 2, fontWeight: 'bold' }}>
          Trade #{displayOrderNumber}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {/* Status Chips */}
        <Chip
          label={fulfillmentStatus}
          color={fulfillmentStatus === 'Fulfilled' ? 'success' : 'warning'}
          sx={{ mr: 1 }}
        />
        <Chip
          label={paymentStatus}
          color={paymentStatus === 'Paid' ? 'success' : 'default'}
          sx={{ mr: 1 }}
        />
        <Chip
          label={deliveryStatus}
          color={deliveryStatus === 'delivered' ? 'success' : 'warning'}
          sx={{ mr: 1 }}
        />
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Main Content */}
      <Grid container spacing={4}>
        {/* Left Column */}
        <Grid item xs={12} md={8}>
          {/* Fulfillment Actions */}
          {/* Order Details */}
          <Paper sx={{ mb: 4, padding: 2 }}>
            <Typography variant="h6" sx={{ textAlign: 'left' }} gutterBottom>
              Order Details
            </Typography>
            {/* Product Item */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar
                variant="square"
                src={productDetails?.thumbnail || '/placeholder.png'}
                sx={{ width: 64, height: 64, mr: 2 }}
              />

            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="body1">{order.product_name || 'Product Name'}</Typography>
            <Typography variant="body2" color="textSecondary">
                SKU: {order.product_id || 'N/A'}
            </Typography>
            <Typography variant="body2" color="textSecondary">
                Name: {productDetails?.title || 'Product Name'}
            </Typography>
            </Box>

              <Typography variant="body1">
                {order.volume} × {formatToDollars(order.price)}
              </Typography>
            </Box>

            <Divider />

            {/* Order Summary */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Box sx={{ width: '50%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">Subtotal</Typography>
                  <Typography variant="body2">
                    {formatToDollars(order.price * order.volume)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">Shipping</Typography>
                  <Typography variant="body2">{formatToDollars(0)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">Tax</Typography>
                  <Typography variant="body2">{formatToDollars(0)}</Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {formatToDollars(order.price * order.volume)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>

          {/* Notes */}
          <Paper sx={{ mb: 4, padding: 2 }}>
            <Typography variant="h6" sx={{ textAlign: 'left' }} gutterBottom>
              Notes
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'left' }}  color="textSecondary">
              No notes from the customer.
            </Typography>
          </Paper>

          {/* Order History */}
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6" sx={{ textAlign: 'left' }} gutterBottom>
              Order History
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Trade executed"
                  secondary={new Date(order.timestamp).toLocaleString()}
                />
              </ListItem>
              {/* Add more history items as needed */}
            </List>
          </Paper>
        </Grid>

        {/* Right Column */}
        {/* Shipment Information */}
        <Grid item xs={12} md={4}>
        <Paper sx={{ mb: 4, padding: 2 }}>
        <Box sx={{ textAlign: 'left' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
            Shipment Information
            </Typography>
            {order.shipment_id && order.tracking_number ? (
            <Box sx={{ mt: 2 }}>
                <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography variant="body2" color="textSecondary">
                    Shipment Status:
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2">{order.shipment_status}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2" color="textSecondary">
                    Tracking Number:
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2">
                    <a
                        href={`https://www.google.com/search?q=track+${order.tracking_number}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {order.tracking_number}
                    </a>
                    </Typography>
                </Grid>
                </Grid>
            </Box>
            ) : (
            <Button
                variant="contained"
                color="primary"
                startIcon={<LocalShippingIcon />}
                onClick={handleOpenTrackingDialog}
                sx={{ mt: 1 }} // Optional: Adds a margin-top for spacing
            >
                Fulfill Items
            </Button>
            )}
        </Box>
        </Paper>

          {/* Customer Information */}
          <Paper sx={{ mb: 4, padding: 2 }}>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Customer
              </Typography>
              {order.address ? (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {order.address.name}
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        Email:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2">{order.address.email}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        Phone:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2">{order.address.phone}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Typography>No Customer Information</Typography>
              )}
            </Box>
          </Paper>

          {/* Shipping Address */}
          <Paper sx={{ mb: 4, padding: 2 }}>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Shipping Address
              </Typography>
              {order.address ? (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    {order.address.name}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    {order.address.street1}
                    {order.address.street2 && `, ${order.address.street2}`}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    {order.address.city}, {order.address.state} {order.address.zip}
                  </Typography>
                  <Typography variant="body2">
                    {order.address.country || 'USA'}
                  </Typography>
                </Box>
              ) : (
                <Typography>No Shipping Address</Typography>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Dialog for adding tracking ID */}
      <Dialog open={openTrackingDialog} onClose={handleCloseTrackingDialog}>
        <DialogTitle>Add Tracking Information</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Select Carrier</InputLabel>
            <Select
              value={carrier}
              onChange={(e) => setCarrier(e.target.value)}
              label="Select Carrier"
              fullWidth
            >
              {carriers.map((carrierOption) => (
                <MenuItem key={carrierOption} value={carrierOption}>
                  {carrierOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            label="Tracking Number"
            type="text"
            fullWidth
            value={trackingID}
            onChange={(e) => setTrackingID(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-start', paddingLeft: 2 }}>
          <Button onClick={handleCloseTrackingDialog} size="small">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddTrackingID}
            size="small"
            sx={{ ml: 1, justifyContent: 'flex-start' }} // Adding margin between buttons
          >
            Add Shipment Label
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrderRowPage;
