import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Box, Typography, Button, Stack, Snackbar, Alert, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';

const OrderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const OrderItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
}));

const OrderHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start', // Align items to the start (left) within each box
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(1.5),
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(1.5),
}));

const OrderDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const OrderActions = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const OrdersPage = ({ marketId }) => {
  const { auth } = useAuth();
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState({});
  const [productNames, setProductNames] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isLocal = process.env.REACT_APP_LOCAL === 'true';

  useEffect(() => {
    const fetchOrders = async () => {
      let fetchedOrders = [];
    
      if (isLocal) {
        try {
          const staticData = await import('./fixtures/orders.json');
          fetchedOrders = staticData.default;
        } catch (error) {
          console.error("Error loading static orders:", error);
        }
      } else {
        try {
          const response = await axios.get(`/api/orders`);
          fetchedOrders = response.data;
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      }
    
      if (fetchedOrders && Array.isArray(fetchedOrders)) {
        // Filter orders by status 'filled' or 'partially filled'
        const filteredOrders = fetchedOrders.filter(order => 
          order.user_id === auth.user?.id &&
          (order.status === 'filled' || order.status === 'partially filled')
        );
        setOrders(filteredOrders);
        fetchProductDetails(filteredOrders);
      } else {
        console.error("Error: fetchedOrders is not an array or is undefined");
      }
    };

    const fetchProductDetails = async (orders) => {
      const productImages = {};
      const productNames = {};
      await Promise.all(
        orders.map(async (order) => {
          try {
            let response;
            if (isLocal) {
              response = await import('./fixtures/productImage.json');
              productImages[order.product_id] = response.img;
              productNames[order.product_id] = response.title;
            } else {
              response = await axios.get(`/api/products/uuid/${order.product_id}`);
              productImages[order.product_id] = response.data.img;
              productNames[order.product_id] = response.data.title;
            }
          } catch (error) {
            console.error(`Error fetching product details for product ID: ${order.product_id}`, error);
          }
        })
      );
      setProducts(productImages);
      setProductNames(productNames);
    };

    if (auth.user) {
      fetchOrders();
    }
  }, [auth.user, marketId, isLocal]);

  return (
    <Container>
      <Box sx={{ margin: 3 }}>
        {orders.map(order => (
          <OrderBox key={order.id}>
            <OrderHeader>
              <Box>
                <Typography variant="body2" color="textSecondary">ORDER PLACED</Typography>
                <Typography variant="body2">{new Date(order.created_at).toLocaleDateString()}</Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textSecondary">TOTAL</Typography>
                <Typography variant="body2">${(order.price / 100).toFixed(2)}</Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textSecondary">SHIP TO</Typography>
                <Typography variant="body2">{auth.user?.name || 'N/A'}</Typography>
              </Box>
              <Box>
                <Typography variant="caption" color="textSecondary" align="right">ORDER ID: {order.id}</Typography>
              </Box>
            </OrderHeader>
            <OrderItem>
              <OrderDetails>
                <Typography variant="h6" gutterBottom>
                  Delivered {new Date(order.created_at).toLocaleDateString()}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {productNames[order.product_id] || 'Product Name'}
                </Typography>
                {products[order.product_id] ? (
                  <img src={products[order.product_id]} alt={productNames[order.product_id]} style={{ maxWidth: '100px', borderRadius: '4px' }} />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Loading image...
                  </Typography>
                )}
              </OrderDetails>
              <OrderActions direction="column" spacing={1}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: 'none', width: '200px', backgroundColor: '#FFD700', color: '#000', '&:hover': { backgroundColor: '#FFC107' } }}
                >
                  Track Package
                </Button>
                <Button
                  variant="contained"
                  sx={{ textTransform: 'none', width: '200px', backgroundColor: '#FFD700', color: '#000', '&:hover': { backgroundColor: '#FFC107' } }}
                >
                  Problem with Order
                </Button>
              </OrderActions>
            </OrderItem>
          </OrderBox>
        ))}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="success">
            Order cancelled successfully!
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default OrdersPage;
