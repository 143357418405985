import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ProductCard from './ProductCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faDollarSign, faBuilding, faGlobe } from '@fortawesome/free-solid-svg-icons';
import './ProductFilter.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const cacheProductsInLocalStorage = (products) => {
  products.forEach(product => {
    const { product_id, title, image_one_thumb } = product;
    const cachedProduct = {
      title: title,
      thumbnail: image_one_thumb
    };
    localStorage.setItem(product_id, JSON.stringify(cachedProduct));
  });
};

const ProductFilter = () => {
  const { filter } = useParams();
  const query = useQuery();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchProducts = useCallback(async (filter, queryString) => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
    const filterMap = {
      canada: 'issuing_country=Canada',
      gold: 'material=Gold',
      swiss: 'manufacturer=Swiss%20Mint'
    };
  
    const filterQuery = filterMap[filter] ? `?${filterMap[filter]}` : '';
    const combinedQuery = filterQuery + queryString;
  
    if (isLocal) {
      try {
        let staticResponse;
        if (filter === 'canada') {
          staticResponse = await import('./fixtures/canada.json');
        } else if (filter === 'gold') {
          staticResponse = await import('./fixtures/gold.json');
        } else if (filter === 'swiss') {
          staticResponse = await import('./fixtures/swiss.json');
        } else {
          staticResponse = await import('./fixtures/products.json');
        }
        setProducts(staticResponse.default);
        cacheProductsInLocalStorage(staticResponse.default); // Cache products in localStorage
      } catch (error) {
        console.error("Error loading static data:", error);
        setError("Error loading static data");
      } finally {
        setLoading(false);
      }
    } else {
      const apiUrl = `/api/products${combinedQuery}`;
      try {
        const response = await axios.get(apiUrl);
        setProducts(response.data);
        cacheProductsInLocalStorage(response.data); // Cache products in localStorage
      } catch (error) {
        console.error("Error fetching product info:", error);
        setError("Error fetching product info");
      } finally {
        setLoading(false);
      }
    }
  }, []);  

  useEffect(() => {
    const queryString = location.search;
    fetchProducts(filter, queryString);
  }, [fetchProducts, filter, location.search]);

  const handleFilterClick = useCallback((filterName) => {
    // Check if the current path matches the target path
    if (location.pathname === `/${filterName}`) {
      return; // Prevent navigation if already on the desired page
    }
    setLoading(true);
    navigate({
      pathname: `/${filterName}`,
    });
  }, [navigate, location.pathname]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="filter-buttons">
        <div className="filter-button">
          <IconButton onClick={() => handleFilterClick('canada')} aria-label="Canada" size="large">
            <FontAwesomeIcon icon={faLeaf} size="large" />
          </IconButton>
          <Typography variant="caption" color="text.secondary">Canadian Coins</Typography>
        </div>
        <div className="filter-button">
          <IconButton onClick={() => handleFilterClick('gold')} aria-label="Gold" size="large">
            <FontAwesomeIcon icon={faDollarSign} size="large" />
          </IconButton>
          <Typography variant="caption" color="text.secondary">Gold Coins</Typography>
        </div>
        <div className="filter-button">
          <IconButton onClick={() => handleFilterClick('swiss')} aria-label="Swiss Mint" size="large">
            <FontAwesomeIcon icon={faBuilding} size="large" />
          </IconButton>
          <Typography variant="caption" color="text.secondary">Swiss Mint</Typography>
        </div>
        <div className="filter-button">
          <IconButton onClick={() => handleFilterClick('')} aria-label="All" size="large">
            <FontAwesomeIcon icon={faGlobe} size="large" />
          </IconButton>
          <Typography variant="caption" color="text.secondary">All</Typography>
        </div>
      </div>
      <Grid container spacing={0} justifyContent="center">
        {products.map((product) => (
          <Grid item key={product.product_id}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ProductFilter;
