import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const FullWidthBox = styled(Box)(({ theme }) => ({
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    margin:  theme.spacing(3),
}));

const FullWidthTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: 'none',
  marginTop: theme.spacing(2),
  width: '100%', // Ensure the container width is 100%
}));

const FullWidthTable = styled(Table)(({ theme }) => ({
  minWidth: '100%', // Ensure the table takes up the full width of its container
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1.5),
  color: theme.palette.text.primary,
}));

export default function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTransactions = async () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    if (isLocal) {
      try {
        const fixtureData = await import('./fixtures/transactions.json');
        setTransactions(fixtureData.default || fixtureData); // Ensure it's an array
      } catch (err) {
        setError('Error loading fixture data');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await fetch('/api/banking/transactions');
        const data = await response.json();
        if (response.ok) {
          setTransactions(Array.isArray(data) ? data : []);
        } else {
          throw new Error(data.message || 'Failed to load transactions');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const pendingTransactions = transactions.filter(txn => txn.status === 'pending');
  const postedTransactions = transactions.filter(txn => txn.status !== 'pending');

  const pendingTotal = pendingTransactions.reduce((sum, txn) => {
    return txn.type === 'debit' ? sum - txn.amount : sum + txn.amount;
  }, 0);

  const postedTotal = postedTransactions.reduce((sum, txn) => {
    return txn.type === 'debit' ? sum - txn.amount : sum + txn.amount;
  }, 0);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString('en-US', { month: 'short', day: 'numeric' });
  };

  return (
    <FullWidthBox>
      <Typography variant="h6" color="text.secondary" sx={{ marginRight: 1 }} align="left">
        My Transactions
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          {/* Pending Transactions Table */}
          <Typography variant="subtitle1" color="text.primary" sx={{ marginTop: 2 }} align="left">
            Pending Transactions
          </Typography>
          <Typography variant="body2" color="text.secondary" align="right">
            Pending Total: ${(pendingTotal / 100).toFixed(2)}
          </Typography>
          <FullWidthTableContainer component={Paper}>
            <FullWidthTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell align="right">Amount</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingTransactions.map((txn) => (
                  <TableRow key={txn.transaction_id}>
                    <StyledTableCell>
                      {formatDate(txn.time_stamp)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {txn.description}
                    </StyledTableCell>
                    <StyledTableCell align="right" style={{ color: txn.type === 'debit' ? 'red' : 'green' }}>
                      {txn.type === 'debit' ? '-' : ''}${(txn.amount / 100).toFixed(2)}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </FullWidthTable>
          </FullWidthTableContainer>

          {/* Posted Transactions Table */}
          <Typography variant="subtitle1" color="text.primary" sx={{ marginTop: 4 }} align="left">
            Posted Transactions
          </Typography>
          <Typography variant="body2" color="text.secondary" align="right">
            Posted Total: ${(postedTotal / 100).toFixed(2)}
          </Typography>
          <FullWidthTableContainer component={Paper}>
            <FullWidthTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell align="right">Amount</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {postedTransactions.map((txn) => (
                  <TableRow key={txn.transaction_id}>
                    <StyledTableCell>
                      {formatDate(txn.time_stamp)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {txn.description}
                    </StyledTableCell>
                    <StyledTableCell align="right" style={{ color: txn.type === 'debit' ? 'red' : 'green' }}>
                      {txn.type === 'debit' ? '-' : ''}${(txn.amount / 100).toFixed(2)}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </FullWidthTable>
          </FullWidthTableContainer>
        </>
      )}
    </FullWidthBox>
  );
}
