import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Fade,
  Backdrop,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import FundingForm from '../IndexPage/FundAccount';
import WithdrawModal from '../WithdrawModal/WithdrawModal';
import { useNavigate } from 'react-router-dom';
import Transactions from './transactions';

const BalanceContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const BalanceBox = styled(Box)(({ theme }) => ({
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: 'none',
  marginTop: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1.5),
  color: theme.palette.text.primary,
}));

export default function Balance() {
  const [balance, setBalance] = useState({ available: 0, pending: 0, total_balance: 0, held_balance:0 });
  const [bankingStatus, setBankingStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFundForm, setShowFundForm] = useState(false);
  const [showWithdrawForm, setShowWithdrawForm] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const navigate = useNavigate();

  const fetchBalance = async () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    if (isLocal) {
      try {
        const fixtureData = await import('./fixtures/balance.json');
        setBalance(fixtureData);
      } catch (err) {
        setError('Error loading fixture data');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await fetch('/api/banking/balance');
        const data = await response.json();
        if (response.ok) {
          setBalance({ 
            available: data.available,
            pending: data.pending,
            total_balance: data.total_balance,
            held_balance: data.held_balance
        });
        } else {
          throw new Error(data.message || 'Failed to load balance');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchBankingStatus = async () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    if (isLocal) {
      try {
        const fixtureData = await import('./fixtures/status.json');
        setBankingStatus(fixtureData);
      } catch (err) {
        setError('Error loading fixture data');
      }
    } else {
      try {
        const response = await fetch('/api/banking/status');
        const data = await response.json();
        if (response.ok) {
          setBankingStatus(data);
        } else {
          throw new Error(data.message || 'Failed to load banking status');
        }
      } catch (err) {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    fetchBalance();
    fetchBankingStatus();
  }, []);

  const handleFundAccountClick = () => {
    setShowFundForm(true);
    setIsButtonDisabled(false);
  };

  const handleWithdrawClick = () => {
    if (bankingStatus?.payouts_enabled) {
      const hasVerifiedAccount = bankingStatus.external_accounts?.some(
        (account) => account.external_accounts_status === 'verified'
      );

      if (hasVerifiedAccount) {
        setShowWithdrawForm(true);
      } else {
        navigate('/withdraw/setup');
      }
    } else {
      navigate('/withdraw/setup');
    }
  };

  const handleInfoModalOpen = () => {
    setInfoModalOpen(true);
  };

  const handleInfoModalClose = () => {
    setInfoModalOpen(false);
  };

  const handleClose = () => {
    setShowFundForm(false);
  };

  const handleWithdrawClose = () => {
    setShowWithdrawForm(false);
  };

  return (
    <div>
      <BalanceContainer>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <BalanceBox sx={{ margin: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" sx={{ marginRight: 1 }}>
                My Cash
              </Typography>
              <IconButton size="small" onClick={handleInfoModalOpen}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography variant="h5" color="text.primary" sx={{ mb: 2, textAlign: 'left' }}>
              Available: ${(balance.available / 100).toFixed(2)}
            </Typography>
            <Typography variant="body1" color="text.primary" sx={{ mb: 2, textAlign: 'left' }}>
              Pending: ${(balance.pending / 100).toFixed(2)}
            </Typography>
            <StyledTableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <Typography variant="body2" color="text.secondary">
                        Name
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Typography variant="body2" color="text.secondary">
                        Total balance
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" color="text.secondary">
                          US Dollar
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      ${(balance.total_balance / 100).toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleWithdrawClick}
                        sx={{ textTransform: 'none', fontWeight: 600, marginRight: 2 }}
                      >
                        Withdraw
                      </Button>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleFundAccountClick}
                        sx={{ textTransform: 'none', fontWeight: 600 }}
                      >
                        Deposit
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableContainer>
          </BalanceBox>
        )}
        <Transactions /> {/* Add the Transactions component here */}
      </BalanceContainer>

      <Modal
        open={infoModalOpen}
        onClose={handleInfoModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={infoModalOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              outline: 'none',
            }}
          >
            <Typography variant="h6" gutterBottom>
              About my cash
            </Typography>
            <Typography variant="body1" gutterBottom>
              When you deposit funds into your account via ACH, please note that the balance will typically become available after the transaction clears, which usually takes 3-5 business days. During this time, the funds may appear as pending, but you won’t be able to use them until they are fully processed and available in your balance. This waiting period ensures the ACH transaction is completed successfully.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleInfoModalClose}
              fullWidth
            >
              Got it
            </Button>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={showFundForm}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showFundForm}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              outline: 'none',
            }}
          >
            {showFundForm && (
              <FundingForm
                onClose={handleClose}
                isButtonDisabled={isButtonDisabled}
                setIsButtonDisabled={setIsButtonDisabled}
              />
            )}
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={showWithdrawForm}
        onClose={handleWithdrawClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showWithdrawForm}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              outline: 'none',
            }}
          >
            {showWithdrawForm && (
              <WithdrawModal
                onClose={handleWithdrawClose}
                isButtonDisabled={isButtonDisabled}
                setIsButtonDisabled={setIsButtonDisabled}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
