import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid'; // MUI DataGrid for table layout
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { ToggleButtonGroup, ToggleButton } from '@mui/material'; // For filter buttons
import axios from 'axios';
import Chip from '@mui/material/Chip'; // For pill-style status
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

const FulfillmentPage = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]); // Filtered orders
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all'); // Track current filter
  const navigate = useNavigate();

  const fetchOrders = useCallback(async () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    if (isLocal) {
      try {
        const mockData = await import('./fixtures/trades.json'); // Local mock data
        setOrders(mockData.default);
        setFilteredOrders(mockData.default); // Set initial filtered orders to the full list
      } catch (error) {
        console.error('Error loading mock data:', error);
        setError('Failed to load mock data');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await axios.get('/api/orders/sell/trades'); // API request for production
        setOrders(response.data);
        setFilteredOrders(response.data); // Set initial filtered orders to the full list
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError('Failed to fetch orders');
      } finally {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  // Handle filter change
  const handleFilterChange = (event, newFilter) => {
    setFilter(newFilter || 'all');
    applyFilter(newFilter || 'all');
  };

  // Apply filtering logic based on the filter state
  const applyFilter = (filter) => {
    if (filter === 'all') {
      setFilteredOrders(orders); // Show all orders
    } else if (filter === 'unfulfilled') {
      setFilteredOrders(orders.filter(order => !order.shipment_id));
    } else if (filter === 'unpaid') {
      setFilteredOrders(orders.filter(order => order.payment_status !== 'Paid'));
    } else if (filter === 'open') {
      setFilteredOrders(orders.filter(order => order.fulfillment_status === 'Open'));
    } else if (filter === 'archived') {
      setFilteredOrders(orders.filter(order => order.status === 'archived')); 
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const columns = [
    { field: 'order_no', headerName: 'Trade', width: 120 },
    { field: 'date', headerName: 'Date', width: 180 },
    { field: 'customer', headerName: 'Customer', width: 180 },
    { field: 'total', headerName: 'Total', width: 120 },
    { field: 'payment_status', headerName: 'Payment Status', width: 150, renderCell: (params) => (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Chip 
          label={params.value} 
          variant="outlined" 
          size="small" 
          color={params.value === 'Paid' ? 'success' : 'default'} 
          sx={{ margin: 'auto', verticalAlign: 'middle' }} // Centering pill and adding margin
        />
      </Box>
    ) },
    { field: 'fulfillment_status', headerName: 'Fulfillment Status', width: 180, renderCell: (params) => (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Chip 
          label={params.value} 
          size="small" 
          color={params.value === 'Fulfilled' ? 'success' : 'warning'} 
          sx={{ margin: 'auto', verticalAlign: 'middle' }} // Centering pill and adding margin
        />
      </Box>
    ) },
    { field: 'items', headerName: 'Items', width: 100 },
    { field: 'delivery_status', headerName: 'Delivery Status', width: 150 },
    { field: 'delivery_method', headerName: 'Delivery Method', width: 180 },
  ];

  // Utility function to format cents to dollars
  const formatToDollars = (cents) => {
    return `$${(cents / 100).toFixed(2)}`;
  };

  const rows = filteredOrders.map((order, index) => ({
    id: index,
    trade_uuid: order.trade_uuid,
    order_no: `${order.trade_uuid.slice(-6)}`, // You can adjust order number formatting
    date: new Date(order.timestamp).toLocaleString(),
    customer: order.address ? `${order.address.name}` : 'No Customer', // Adjust if you have real customer data
    total: `${formatToDollars(order.volume * order.price)}`,
    payment_status: 'Paid', // Assume 'Paid' for all orders as an example
    fulfillment_status: order.shipment_id ? 'Fulfilled' : 'Unfulfilled', // Use shipment_id to determine fulfillment status
    items: `${order.volume} item(s)`,
    delivery_status: 'Shipping', 
    delivery_method: 'Standard Shipping', 
  }));

  

  // Use onRowClick to navigate based on trade_uuid
  const handleRowClick = (params) => {
    const trade_uuid = params.row.trade_uuid;
    navigate(`/trades/${trade_uuid}`);
  };
  return (
    <div style={{ padding: '10px' }}>
      <Typography variant="h6" gutterBottom align="left">
        Trades
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilterChange}
          sx={{ marginBottom: '10px', width: '100%', height: '36px' }}
          aria-label="filter orders"
        >
          <ToggleButton value="all" aria-label="all orders" sx={{ width: '20%', height: '36px', borderRadius: '0px', fontSize: '0.75rem' }}>
            ALL
          </ToggleButton>
          <ToggleButton value="unfulfilled" aria-label="unfulfilled orders" sx={{ width: '20%', height: '36px', borderRadius: '0px', fontSize: '0.75rem' }}>
            UNFULFILLED
          </ToggleButton>
          <ToggleButton value="unpaid" aria-label="unpaid orders" sx={{ width: '20%', height: '36px', borderRadius: '0px', fontSize: '0.75rem' }}>
            UNPAID
          </ToggleButton>
          <ToggleButton value="open" aria-label="open orders" sx={{ width: '20%', height: '36px', borderRadius: '0px', fontSize: '0.75rem' }}>
            OPEN
          </ToggleButton>
          <ToggleButton value="archived" aria-label="archived orders" sx={{ width: '20%', height: '36px', borderRadius: '0px', fontSize: '0.75rem' }}>
            ARCHIVED
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <div style={{ height: 400, width: '100%' }}>
        <DataGrid 
          rows={rows} 
          columns={columns} 
          pageSize={5} 
          rowHeight={40}
          onRowClick={handleRowClick}  // Use onRowClick to trigger navigation
          sx={{
            fontSize: '0.875rem',
            '& .MuiDataGrid-cell': {
              padding: '10px',
              alignItems: 'center',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f5f5f5',
              fontSize: '0.875rem',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            }
          }}
        />
      </div>
    </div>
  );
};

export default FulfillmentPage;
