import React, { useState } from 'react';
import { Box, Typography, Paper, Avatar, Fab, Button, Divider } from '@mui/material';
import goldBarImage from './clippy.png';

const FloatingHelper = () => {
  const [showHelper, setShowHelper] = useState(false);

  const handleHelperClick = () => {
    setShowHelper(!showHelper);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      {showHelper ? (
        <Paper
          sx={{
            width: 300,
            padding: 2,
            boxShadow: 3,
            borderRadius: 4,
            mb: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Avatar
              sx={{ width: 60, height: 60, mr: 2 }}
              src={goldBarImage} // Use the imported .png image here
              alt="Gold Bar"
            />
          <Typography variant="h6" sx={{ textAlign: 'left' }}>
            Howdy, partner! I'm Gold Bar, here to help you navigate the world of coin tradin'. Pew pew!
          </Typography>          </Box>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body1" sx={{ mb: 2 }}>
            Need help roundin' up some gold or placin' a trade?
          </Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{ mb: 1, textTransform: 'none' }}
          >
            I want to learn about gold and silver coins
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{ mb: 1, textTransform: 'none' }}
          >
            I need technical support
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{ mb: 1, textTransform: 'none' }}
          >
            I have an account and billing issue
          </Button>
          <Button
            variant="outlined"
            fullWidth
            sx={{ textTransform: 'none' }}
            onClick={handleHelperClick}
          >
            Close
          </Button>
        </Paper>
      ) : (
        <Fab
          onClick={handleHelperClick}
          sx={{
            width: 80,
            height: 80,
            backgroundImage: `url(${goldBarImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      )}
    </Box>
  );
};

export default FloatingHelper;
