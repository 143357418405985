import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <Box component="footer" sx={{ p: 4, bgcolor: 'background.paper', borderTop: 1, borderColor: 'divider' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Goldwater Bullion Exchange
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Trusted bullion trading platform ensuring secure and transparent transactions for all your gold and silver investments.
              </Typography>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Link href="#" color="inherit">
                  <InstagramIcon />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Trending Markets
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="body2" color="text.secondary">1 Ounce Gold Eagle</Typography>
                <Typography variant="body2" color="text.secondary">1 Ounce Silver Eagle</Typography>
                <Typography variant="body2" color="text.secondary">1 Ounce Gold Buffalo</Typography>
                <Typography variant="body2" color="text.secondary">Gold Bars</Typography>
                <Typography variant="body2" color="text.secondary">Silver Bars</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Shop by Concern
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="body2" color="text.secondary">Gold Bug</Typography>
                <Typography variant="body2" color="text.secondary">Hedge Inflation</Typography>
                <Typography variant="body2" color="text.secondary">Silver Squeeze</Typography>
                <Typography variant="body2" color="text.secondary">Diversify Portfolio</Typography>
                <Typography variant="body2" color="text.secondary">Safe Haven Asset</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                More about Goldwater
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="body2" color="text.secondary">Our Story</Typography>
                <Typography variant="body2" color="text.secondary">Wholesale Opportunities</Typography>
                <Typography variant="body2" color="text.secondary">Customer Service</Typography>
                <Typography variant="body2" color="text.secondary">My Account</Typography>
                <Typography variant="body2" color="text.secondary">My Fills</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            © Goldwater Bullion Exchange, Inc. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
