import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { FaMoneyBill, FaThumbsUp, FaTrophy, FaBell } from 'react-icons/fa';

const GradientBox = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(90deg, #FF8A80, #FF8E53, #FFC107, #C5E1A5, #81D4FA)',
  padding: '20px',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '20px 0', // Adds margin above and below
}));

const IconWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',
});

const Text = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '16px',
});

const Description = styled(Typography)({
  color: '#666',
  fontSize: '14px',
});

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ my: 2 }}>
      <GradientBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} style={{ display: isMobile ? 'block' : 'flex' }}>
            <Box display="flex" alignItems="center">
              <FaTrophy size={24} />
              <Box ml={1}>
                <Text>Breakout Brand of the Year</Text>
                <Description>Techcrunch Featured</Description>
              </Box>
            </Box>
          </Grid>
          {!isMobile && (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="center">
                  <FaThumbsUp size={24} />
                  <Box ml={1}>
                    <Text>Huge inventory</Text>
                    <Description>hundreds of coins</Description>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="center">
                  <FaMoneyBill size={24} />
                  <Box ml={1}>
                    <Text>Make your best offer</Text>
                    <Description>Rules-based markets.</Description>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="center">
                  <FaBell size={24} />
                  <Box ml={1}>
                    <Text>Patriot Owned</Text>
                    <Description>Priority is Liberty.</Description>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </GradientBox>
    </Box>
  );
};

export default Hero;
