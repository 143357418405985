import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';

const ProductCard = React.memo(({ product }) => {
  const [favorited, setFavorited] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);

  const handleFavoriteClick = () => {
    setFavorited(!favorited);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Card sx={{ maxWidth: 195, maxHeight: 300, margin: 2, minHeight: 300, minWidth: 195 }}>
      <CardMedia
        sx={{ height: 200, position: 'relative' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        title={product.title}
      >
        {hovered && product.video ? (
          <video
            src={product.video}
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <img
            src={product.image_one_thumb && product.image_one_thumb.length > 0 ? product.image_one_thumb : 'https://slideby.s3.us-west-1.amazonaws.com/placeholder-image.png'}
            alt={product.title}
            width="100%"
            height="100%"
            style={{objectFit: 'contain', width: '100%', height: '100%' }}
          />
        )}
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8, color: 'white', backgroundColor: 'rgba(0,0,0,0.1)' }}
          onClick={handleFavoriteClick}
        >
          <FontAwesomeIcon icon={favorited ? solidStar : regularStar} />
        </IconButton>
      </CardMedia>
      <CardContent sx={{ padding: 1, overflow: 'hidden' }}>
        <Typography gutterBottom variant="caption" component="div" align="left" noWrap>
          {product.manufacturer}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-start', padding: 1 }}>
        <Link href={`/products/${product.slug}`} sx={{ textDecoration: 'none' }}>
          <Button size="small">
            <Typography
                sx={{
                  textOverflow: 'ellipsis',
                }}
            >{product.title}</Typography>
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
});

export default ProductCard;
