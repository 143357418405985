import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Snackbar, Alert, Box } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { styled } from '@mui/material/styles';

const OrderBox = styled(Box)(({ theme }) => ({
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
}));

const MyOrders = ({ productName, marketId }) => {
  const { auth } = useAuth();
  const [myOrders, setMyOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const isLocal = process.env.REACT_APP_LOCAL === 'true';

  useEffect(() => {
    const fetchMyOrders = async () => {
      let staticOrders = [];

      if (isLocal) {
        try {
          const staticData = await import('./fixtures/orders.json');
          staticOrders = staticData.default;
        } catch (error) {
          console.error("Error loading static orders:", error);
        }
      } else {
        try {
          const response = await axios.get(`/api/markets/${marketId}/orders`);
          staticOrders = response.data;
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      }

      if (staticOrders && Array.isArray(staticOrders)) {
        const userOrders = staticOrders.filter(order => order.user_id === auth.user?.id);
        setMyOrders(userOrders);
      } else {
        console.error("Error: staticOrders is not an array or is undefined");
      }
    };

    if (auth.user) {
      fetchMyOrders();
    }
  }, [auth.user, marketId, isLocal]);

  const handleRowClick = (order) => {
    setSelectedOrder(order.id === selectedOrder ? null : order);
  };

  const handleCancelOrder = async (orderId) => {
    if (isLocal) {
      setTimeout(() => {
        setMyOrders(prevOrders =>
          prevOrders.map(order =>
            order.id === orderId ? { ...order, status: 'cancelled' } : order
          )
        );
        setSnackbarOpen(true);
        setSelectedOrder(null);
      }, 500);
    } else {
      try {
        const response = await axios.patch(`/internal/api/orders/${orderId}`, { status: 'Cancelled' });
        if (response.status === 204) {
          setMyOrders(prevOrders =>
            prevOrders.map(order =>
              order.id === orderId ? { ...order, status: 'cancelled' } : order
            )
          );
          setSnackbarOpen(true);
          setSelectedOrder(null);
        }
      } catch (error) {
        console.error("Error cancelling order:", error);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Filter logic adjusted to include "new" orders for open orders.
  const filteredOrders = myOrders.filter(order => {
    if (selectedTab === 0) {
      return order.status === 'open' || order.status === 'partially filled' || order.status === 'new';
    } else if (selectedTab === 1) {
      return order.status === 'filled';
    }
    return false;
  });

  if (auth.loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div className="my-orders">
      <OrderBox>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Open Orders" />
          <Tab label="Filled Orders" />
        </Tabs>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="My Orders Table">
            <TableHead>
              <TableRow>
                <TableCell align="left">TIME PLACED</TableCell>
                <TableCell align="left">NAME</TableCell>
                <TableCell align="left">STATUS</TableCell>
                <TableCell align="left">SIDE</TableCell>
                <TableCell align="left">PRICE</TableCell>
                <TableCell align="left">AMOUNT</TableCell>
                <TableCell align="center">ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  onClick={() => handleRowClick(order)}
                  selected={selectedOrder && selectedOrder.id === order.id}
                >
                  <TableCell align="left">
                    {new Date(order.created_at).toLocaleString(undefined, { timeZoneName: 'short' })}
                  </TableCell>
                  <TableCell align="left">{productName || 'N/A'}</TableCell>
                  <TableCell align="left">{order.status}</TableCell>
                  <TableCell align="left">{order.type}</TableCell>
                  <TableCell align="left">${order.price / 100}</TableCell>
                  <TableCell align="left">{order.currentvolume}</TableCell>
                  <TableCell align="center">
                    {/* Show cancel button only if the order is not cancelled or filled */}
                    {selectedOrder && selectedOrder.id === order.id && order.status !== 'cancelled' && order.status !== 'filled' && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleCancelOrder(order.id)}
                      >
                        Cancel
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </OrderBox>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Order cancelled successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MyOrders;
